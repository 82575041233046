import React from 'react';

import {
  // Caption,
  AspectRatioBox,
  // Section,
  Panel,
  // Grid,
  // GridItem,
  // VisibleAbove,
  // Curtain,
  // VisibleBelow,
  Centered,
  Caption,
  Image,
  ItemsGroup,
  ImageList,
  Flex,
} from '@sdflc/ui';

import { withProviders } from '../../../hoc';

//import { SubscribeForm } from '../forms';
import { colors, siteInfo } from '../../../config';
import { DefaultLayout } from '../../../layouts';

const CashDrawerCounterIndexPage = () => {
  const appIcon = `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/appicon512.png`;
  return (
    <DefaultLayout title={siteInfo.title}>
      <ItemsGroup distance={20}>
        <Image src={appIcon} alt='' width={64} />
        <Caption level={1} noMargin>
          CashDrawer Counter
        </Caption>
      </ItemsGroup>
      <Flex width='100%' flexDirection='column' alignItems='center'>
        <ImageList
          height={300}
          images={[
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-001.jpg`,
              alt: '',
              caption: '',
            },
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-002.jpg`,
              alt: '',
              caption: '',
            },
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-003.jpg`,
              alt: '',
              caption: '',
            },
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-004.jpg`,
              alt: '',
              caption: '',
            },
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-005.jpg`,
              alt: '',
              caption: '',
            },
            {
              src: `${siteInfo.assetsBaserUrl}/products/cashdrawer-counter/cashdrawer-screenshot-006.jpg`,
              alt: '',
              caption: '',
            },
          ]}
        />
      </Flex>
    </DefaultLayout>
  );
};

export default withProviders(CashDrawerCounterIndexPage);
